import { useLazyQuery } from "@apollo/client";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Airport, GetAirportsDocument } from "graphql/generated";
import { useEffect, useState } from "react";
import { removeK } from "utils/formatter";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export type Route = {
    id?: string;
    icao: string;
};



export default function RouteItem(
{   route, 
    index, 
    temp = true,
    handleRemoveItem, 
    handleItemUpdate 
} : {
    route: Route;
    index: number;
    temp?: boolean;
    handleRemoveItem: (index: number) => void;
    handleItemUpdate: (index: number, type : string, value: string) => void;
}) {
    const [search, { data: { airports } = { airports: [] } }] = useLazyQuery(GetAirportsDocument);
    useEffect(() => {
        const timeOutId = setTimeout(
            () =>
            search({
                variables: {
                    where: {
                        icao: {
                            contains: route.icao.trim().replaceAll(' ', ' & '),
                        },
                    },
                    limit: 4
                },
            }),
            500
        );
        return () => clearTimeout(timeOutId);
    }, [route.icao, search]);
    
    useEffect(() => {
        if(airports.find((airport) => airport.icao === route.icao)) {
            handleItemUpdate(index, 'id', airports.find((airport) => airport.icao === route.icao)?.id ?? '');
        }
    }, [route.icao, airports]);

    useEffect(() => {
        if(route.icao !== ''){
            handleItemUpdate(index, 'id', '');
        }
    }, [route.icao]);

    return ( <div className="flex w-full gap-2 mb-9">
        <Combobox value={route.icao}>
            <div className={'relative w-full'}>
                <Combobox.Input<'input', any>
                className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full"
                onChange={(event) => handleItemUpdate(index, 'icao', event.target.value.toUpperCase())}
                value={route.icao}
                placeholder="Search for an Airport"
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {airports.length > 0 && (
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {airports.map((airport) => (
                    <Combobox.Option<'li', string>
                        key={airport.id}
                        value={removeK(airport.icao)}
                        onClick={() => { 
                            handleItemUpdate(index, 'id', airport.id);
                            handleItemUpdate(index, 'icao', removeK(airport.icao));
                        }}
                        className={({ active }) =>
                            classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                        }>
                        {({ active, selected }) => (
                        <>
                            <div className="flex">
                                <span className={classNames('truncate', selected && 'font-semibold')}>{removeK(airport.icao)}</span>
                                <span className={classNames('ml-2 truncate text-gray-500', active ? 'text-indigo-200' : 'text-gray-500')}>
                                    {airport.facilityName}
                                </span>
                            </div>

                            {selected && (
                            <span
                                className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600'
                                )}>
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                            )}
                        </>
                        )}
                    </Combobox.Option>
                    ))}
                </Combobox.Options>
                )}
            </div>
        </Combobox>
        { temp &&
        <TrashIcon
            onClick={() => {
                handleRemoveItem(index);
            }}
            title="Delete"
            className="text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50 transition"
        />  }
    </div> );
    
}