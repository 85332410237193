import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { FullFormikInput, FullFormikTextarea } from "components/Form/StandardForm";
import { Table } from "components/Table/Table";
import Toast, { ToastLength, useToast } from "components/Toast/Toast";
import { useSession } from "contexts";
import { Formik, Form } from "formik";
import { GetLocationsDocument, GetMroOrganizationIconsDocument, GetMroOrganizationInfoDocument, UpdateMroOrganizationDocument, UpdateOrganizationDocument, UpdateOrganizationIconDocument } from "graphql/generated";
import { useMemo, useRef, useState } from "react";
import { formatApiDate } from "utils/formatter";

export default function OrganizationSettings() {
    const { user } = useSession();
    const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
    const [colorLogo, setColorLogo] = useState<File>(null);
    const [blackLogo, setBlackLogo] = useState<File>(null);
    const blackLogoRef = useRef(null);
    const colorLogoRef = useRef(null);
    const { data: { mroLocations } = {} } = useQuery(GetLocationsDocument, { variables: { where : { mroOrganizationId: { equals: user?.mroOrganizationId } } } });
    const [updateOrganizationIcon] = useMutation(UpdateOrganizationIconDocument);
    const [updateOrganization] = useMutation(UpdateMroOrganizationDocument, { refetchQueries: [GetMroOrganizationInfoDocument] });
    const { refetch, data: { mroOrganization: { mroBlackAndWhiteIcon, mroColorIcon } } = { mroOrganization : { mroBlackAndWhiteIcon: undefined, mroColorIcon: undefined }  } } = useQuery(GetMroOrganizationIconsDocument, { variables: { id: user?.mroOrganizationId } });
    const { data : { mroOrganizationInfo } = {} } = useQuery(GetMroOrganizationInfoDocument, { variables: { id: user.mroOrganizationId } });
    const { showToast, toastProps } = useToast();
    const formPrefill = {
        ...mroOrganizationInfo
    };
    delete formPrefill.icon;
    const columns = useMemo(
        () => [
            {
                Header: 'Code',
                accessor: 'code',
                className: 'w-1/6',
            },
            {
                Header: 'Name',
                accessor: 'name',
                className: 'w-3/6',
            },
            {
                Header: 'Created',
                accessor: 'createdAt',
                className: 'w-2/6',
                Cell: ({ value }) => formatApiDate(value),
            },
        ],
        []
    );
    const handleLogoSave = async() => {
        let colorIconId = undefined;
        let blackIconId = undefined;
        if(colorLogo){
            const colorData = new FormData();
            colorData.append('files', colorLogo);
            const resColor = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: colorData });
            const bodyColor = await resColor.json();
            colorIconId = bodyColor[0].id;
        }

        if(blackLogo) {
            const blackData = new FormData();
            blackData.append('files', blackLogo);
            const resBlack = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: blackData });
            const bodyBlack = await resBlack.json();
            blackIconId = bodyBlack[0].id;
        }
        await updateOrganizationIcon({
            variables : {
                organizationId: user.mroOrganizationId,
                colorIconId,
                blackIconId,
            }
        });
        refetch();
        blackLogoRef.current.value = '';
        colorLogoRef.current.value = '';
    };    
    return (
        <>
            <Toast position="top" {...toastProps} />
            <Flyout {...flyoutProps} />
            <Formik
            enableReinitialize
            initialValues={formPrefill}
            onSubmit={async (values: typeof formPrefill) => {
                await updateOrganization({ variables: { input: { id: user.mroOrganizationId, name: values.name, address: { 
                    update: {
                       data:{
                        phone: values.phone,
                        fax: values.fax,
                        address: values.address,
                        city: values.city,
                        region: values.region,
                        postalCode: values.postalCode,
                       }
                    }
                } } } });
                showToast({ type: ToastLength.Normal, title: 'Organization Info Updated' });
            }}>
                <Form className="flex flex-col w-full gap-2 rounded-l">
                    <Card title="Organization Info">
                        <div className="flex flex-col items-start">
                            <FullFormikInput name="name" placeholder="Organization Name" label="Name" />
                            <div className="flex gap-2 w-full">
                                <FullFormikInput name="phone" placeholder="1234567890" label="Phone" />
                                <FullFormikInput name="fax" placeholder="1234567890" label="Fax" />
                            </div>
                            <FullFormikInput name="address" placeholder="101 Test Street" label="Address" />
                            <div className="flex gap-2 w-full">
                                <FullFormikInput name="city" placeholder="New York City" label="City" />
                                <FullFormikInput name="region" placeholder="NY" label="State" />
                                <FullFormikInput name="postalCode" placeholder="NY" label="Zip Code" />
                            </div>
                            <FullFormikTextarea name="estimateAuthorization" placeholder="Insert Maintenance Authorization" label="Maintenance Authorization" />
                        </div>
                        <div className="flex justify-end">
                            <Button text="Save" color="navy" size="xs" type="submit" />
                        </div>
                    </Card>
                    <Card title="Organization Locations">
                        <div className="flex items-center gap-2 border border-slate-300 rounded">
                            <Table columns={columns} 
                            data={mroLocations}/>
                        </div>
                    </Card>
                    <Card title="Organization Logos">
                        <div className="flex flex-col gap-4 mt-4">
                            <div className="flex items-center gap-2 border-b-2 py-3">
                                <label className="w-1/6">Color Logo</label>
                                <input type="file" ref={colorLogoRef} accept="image/png, image/jpeg" onChange={(e) => setColorLogo(e.target.files[0])} />
                                { mroColorIcon?.signedUrl && <img src={mroColorIcon?.signedUrl} alt="Color Logo" className="w-20" /> }
                            </div>
                            <div className="flex items-center gap-2">
                                <label className="w-1/6">Black Logo</label>
                                <input type="file" ref={blackLogoRef} accept="image/png, image/jpeg" onChange={(e) => setBlackLogo(e.target.files[0])} />
                                { mroBlackAndWhiteIcon?.signedUrl && <img src={mroBlackAndWhiteIcon?.signedUrl} alt="Black Logo" className="w-20" /> }
                            </div>

                            <div className="flex justify-end">
                                <Button text="Save" color="navy" size="xs" onClick={handleLogoSave} />
                            </div>
                        </div>
                    </Card>
                </Form>
            </Formik>
        </>
    )
}