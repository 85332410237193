import { Table } from "components/Table/Table";
import { useMemo } from "react";
import { Reservation } from "graphql/generated";
import { applyTimezone, formatApiDateTimeUTC, formatApiDateUTC } from "utils/formatter";
import { useSession } from "contexts";
function List({ data, rowClicked }:{data:Reservation[], rowClicked:(any)=>void}) {
  const { user } = useSession();
  const columns = useMemo(
      () => [
        {
          Header: 'From',
          accessor: 'from',
          className: 'text-left w-2/12',
          Cell: ({ value, row }: any) => {
            console.log(row.original);
            return (
              <div className="w-full h-full text-left">
                <button>{row?.original?.allDay? formatApiDateUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone))) : formatApiDateTimeUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone)).toISOString())}</button>
              </div>
            );
          },
        },
        {
          Header: 'To',
          accessor: 'to',
          className: 'text-left w-2/12',
          Cell: ({ value, row }: any) => {
            return (
              <div className="w-full h-full text-left">
                <button>{row?.original?.allDay ? formatApiDateUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone))) : formatApiDateTimeUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone)).toISOString())}</button>
              </div>
            );
          },
        },
        {
          Header: 'Craft',
          accessor: (row: any) => `${row?.craft?.craftName ?? ''}`,
          className: 'text-left  w-2/12',
        },
        {
          Header: 'Pilot',
          accessor: (row: any) => `${row?.pilot?.user?.firstName ?? ''} ${row?.pilot?.user?.lastName ?? ''}`,
          className: 'text-left w-2/12',
        },
        {
          Header: 'Notes',
          accessor: 'notes',
          className: 'text-center w-5/12 truncate overflow-hidden text-ellipsis',
        },
      ],
      []
  );
  return <div className="p-6 bg-white border rounded border-slate-300 w-full shadow-blue">
      <Table
        className="w-full"
        columns={columns}
        data={data}
        onRowClick={rowClicked}
        />
  </div>
}
export default List;