import Paywall from "components/Paywall/Paywall";
import SegmentControl from "components/SegmentControl/SegmentControl";
import { useSession } from "contexts";
import { useState } from "react";
import { OrgSubtypes } from "utils/orgTypes";

const Announcements = function() {
  const { organizationSubtype } = useSession();
  const [selectedTab, setSelectedTab] = useState('Flights');
  return(
    <>
      {organizationSubtype !== OrgSubtypes.MULTI ? <Paywall /> : 
        <div className="flex flex-col">
          {/* Header */}
          <div className="flex justify-between items-center gap-10">
            <h2 className="text-brand-dark font-bold text-xl">Announcements</h2>
            
          </div>
        </div>
      }
    </>
  );
}

export default Announcements;