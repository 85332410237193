import { useContext, useState } from 'react';
import Button from 'components/Button/Button';
import Logo from 'assets/icons/logo.svg';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GetPublicMroOrganizationsInfoDocument, LookupTailDocument } from 'graphql/generated';
import { ServiceFormContext } from '..';

const SelectShop = (props) => {
  const { data: { getPublicMroOrganizationsInfo } = {} } = useQuery(GetPublicMroOrganizationsInfoDocument, { variables:{  } });
  const { setMroOrganization, mroOrganization, changeStep, formStep, user } = useContext(ServiceFormContext);
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    
  };
  return (
    <div className="text-left w-96 flex flex-col p-6 -mt-[40px] md:mt-0 md:p-0">
      <span className="text-3xl md:text-2xl font-bold text-brand mb-8 md:mb-4">Select Shop</span>
      { getPublicMroOrganizationsInfo?.map((shop) => ( 
        <div className='border-brand border rounded gap-8 flex items-center p-3 mr-14 mb-5' key={shop.id} onClick={() => setMroOrganization(shop)}>
          <input type="radio" name="shop" checked={ shop.id === mroOrganization?.id } />
          <div className='flex flex-col'>
            <span>{shop?.name}</span>
            {shop?.address ? <span>{shop?.address}</span>: ""}
            {shop?.address && <span>{shop?.city}, {shop?.region}</span>}
          </div>          
        </div>
       ))}
       <div className="flex justify-between items-center">
        <button className="underline text-brand font-semibold text-sm" type="button" onClick={() => changeStep(formStep, false, true)}>
          Go Back
        </button>
        <Button text="Next" color="navy" size="xs" className='disabled:opacity-50' disabled={!mroOrganization} type="submit" onClick={()=>{
          changeStep(formStep, user ? "craftSelect" : 'tailLookup');
        }} />
      </div>
    </div>
  );
};

export default SelectShop;
