import { useEffect, useMemo, useState } from 'react';
import { Table } from 'components/Table/Table';
import { useMutation, useQuery } from '@apollo/client';
import { GetInspectionDocument, GetInspectionsDocument, GetIntervalsForCraftDocument, InspectionDueType, UpdateInspectionDocument } from 'graphql/generated';
import Button from 'components/Button/Button';
import { Field, Form, Formik } from 'formik';
import { FormLabel, FullFormikInput, FullFormikTextarea } from 'components/Form/StandardForm';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { formatApiDate, formatDateChange } from 'utils/formatter';
import DatePicker from 'components/DatePicker/DatePicker';
import { PencilIcon } from '@heroicons/react/24/solid';
import EditInspectionFlyout from '../Edit';
import { useSession } from 'contexts';
import EditEntryFlyout from '../EditEntry';

export default function ViewInspection({ id, closeFlyout } : { id: string, closeFlyout: () => void } ){
    const { data: { inspection } = {} }  = useQuery(GetInspectionDocument, { variables: { id } });
    const { craftId } = useSession();
    const { flyoutProps, closeFlyout: closeEdit, updateFlyout } = useFlyout(); 
    const [ updateInspection ] = useMutation(UpdateInspectionDocument, { refetchQueries: [ { query: GetInspectionDocument, variables: { id } }, { query: GetInspectionsDocument, variables: { craftId }  } ] });
    const { data: { craft : { intervalsNonConsumable: intervals } = {} } = {} } = useQuery(GetIntervalsForCraftDocument, { variables: { craftId } });
    const today = new Date();
    today.setHours(0, 0, 0, 0);


    const intervalName = inspection?.dueInterval ? Object.keys(inspection?.dueInterval)[0] : '';
    const intervalId = intervals?.find((interval) => interval.name === intervalName)?.id;
    const [ initialValues, setInitialValues ] = useState({
        datePerformed: today,
        lastPerformedInterval: inspection?.lastPerformedInterval?.[intervalName],
        dueInterval: '0',
        dueDate: today,
        description: '',
    });
    useEffect(() => {
        if(inspection){
            const dueEvery = intervalName && inspection?.dueInterval[intervalName]?.dueEvery;
            let daysTillDue = new Date();
            if(inspection.dueDateDaysFrequency){
                daysTillDue.setDate(daysTillDue.getDate()+Number(inspection.dueDateDaysFrequency));
            }
            if(inspection.dueDateMonthsFrequency){
                daysTillDue.setMonth(daysTillDue.getMonth()+Number(inspection.dueDateMonthsFrequency));
            }
            const dueIntervalValue = (Number(inspection?.lastPerformedInterval?.[intervalName] ?? inspection?.newestInterval?.recentIntervalLog?.endValue)+Number(dueEvery));

            setInitialValues({
                datePerformed: today,
                lastPerformedInterval: inspection?.lastPerformedInterval?.[intervalName] ?? inspection?.newestInterval?.recentIntervalLog?.endValue,
                dueInterval: isNaN(dueIntervalValue) ? '0' : dueIntervalValue.toFixed(2),
                description: '',
                dueDate: new Date(daysTillDue),
            });
        }
    }, [inspection]);
    const columns = useMemo(
    () => [
        {
            Header: 'Date Performed',
            accessor: 'completionDate', // accessor is the "key" in the data
            className: 'w-2/6',
            Cell: ({ value, row }: any) => {
                return formatApiDate(value);
            },
        },
        {
            Header: intervalName ?? '',
            accessor: 'intervalValues',
            className: 'w-2/6',
            Cell: ({ value, row }: any) => { 
                if(inspection?.dueMethod === InspectionDueType.Interval || inspection?.dueMethod === InspectionDueType.Both){
                    return value?.[intervalName] ?? '';
                }
                return '';

            },
        },
        {
            Header: 'Notes',
            accessor: 'description',
            className: 'w-2/6',
        },
    ], [inspection] );
    return (
        <>
            <Flyout {...flyoutProps} />
            <div className="flex flex-col w-full items-center justify-center p-4">
                <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values) => {
                    const interval = inspection?.newestInterval;
                    const inspectionName = inspection?.dueInterval ? Object.keys(inspection?.dueInterval)[0] : '';
                    await updateInspection({ variables: { input: { id, 
                        lastPerformed: new Date(values.datePerformed), 
                        ...(interval && { lastPerformedInterval: { [inspectionName]: values.lastPerformedInterval } }),
                        ...(inspection?.dueMethod !== InspectionDueType.Interval && { dueDate: new Date(values.dueDate) }),
                        ...(inspection?.dueMethod !== InspectionDueType.Date && { intervalDueRecords: { create: [ { dueAt: values.dueInterval, interval: { connect: { id: intervalId } } } ] } }),
                        maintenanceRecords: { create: [ { completionDate: new Date(values.datePerformed), name: "Maintenance Record", description: values.description, craft: { connect: {id: craftId} }, intervalValues: {
                            [intervalName]: values.lastPerformedInterval
                        } } ] } 
                    } } });
                }}>
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form className="w-full border rounded p-8 shadow bg-white">
                            <div className="flex items-center justify-between mt-[-10px] mb-5">
                                <h1 className="font-bold text-lg md:text-xl">{inspection?.name}</h1>
                                <PencilIcon className='h-4 w-4 cursor-pointer' onClick={()=>updateFlyout({ title: 'Edit Inspection', content: <EditInspectionFlyout id={id} closeFlyout={(didDelete)=>{
                                    closeEdit();
                                    if(didDelete){
                                        closeFlyout();
                                    }
                                }}/> })} />
                            </div>
                            <div className="flex w-full items-center mb-2 gap-4">
                                <div className='flex flex-col w-1/2'>
                                    <FormLabel className='mt-[-10px] text-sm font-medium text-brand'>Date Performed:</FormLabel>
                                    <Field as={DatePicker} className="w-full" name="datePerformed"  afterChange={(e) => {
                                        let dueDate = formatDateChange(e.target.value) as Date;
                                        if(inspection.dueDateDaysFrequency){
                                            dueDate.setDate(dueDate.getDate()+Number(inspection.dueDateDaysFrequency));
                                        }
                                        if(inspection.dueDateMonthsFrequency){
                                            dueDate.setMonth(dueDate.getMonth()+Number(inspection.dueDateMonthsFrequency));
                                        }
                                        setFieldValue('dueDate', dueDate);
                                    }} />
                                </div>
                                { inspection?.dueMethod !== InspectionDueType.Date && <FullFormikInput name="lastPerformedInterval" onChange={(e) =>{
                                    setFieldValue('lastPerformedInterval', e.target.value);
                                    setFieldValue('dueInterval', (Number(e.target.value)+Number(inspection?.dueInterval[intervalName]?.dueEvery)).toString());
                                }} label={`Last ${intervalName} Performed:`} autoComplete="off" className='flex flex-col w-1/2' /> }
                            </div>
                            <FullFormikTextarea name="description" label="Notes" autoComplete="off" className='flex flex-col w-full mb-2' />
                            <div className="flex w-full items-center mb-2 gap-4">
                                { inspection?.dueMethod !== InspectionDueType.Interval && inspection?.doesRepeat !== false && 
                                    <div className='flex flex-col w-1/2 mt-[-10px]'>
                                        <FormLabel className='mt-[-1px] text-sm font-medium text-brand'>Due Date:</FormLabel>
                                        <Field as={DatePicker} className="w-full" name="dueDate" />
                                    </div>
                                }
                                { inspection?.dueMethod !== InspectionDueType.Date && 
                                    <div className='flex flex-col w-1/2'>
                                        <FullFormikInput name="dueInterval" label={`Due ${intervalName}:`} autoComplete="off" className='flex flex-col ml-1' />
                                    </div>
                                }
                            </div>
                            <hr className="my-2" />
                            <span className='text-xs'>{inspection?.airworthinessItem ? '* this is an Airworthy Item': ''}</span>
                            <div className={`flex items-center justify-end ${inspection?.airworthinessItem ? 'mt-[-20px]' : ''}`}>
                                <Button text="Save" color="navy" type='submit' size="xs" />
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className="w-full border rounded p-8 shadow bg-white mt-4">
                    <h1 className="font-bold mb-4">Previous Entries</h1>
                    <Table className="border w-full" columns={columns} data={[...inspection?.maintenanceRecords ?? []]?.sort((record) => record?.completionDate)} onRowClick={(row) => { 
                        updateFlyout( { title: "Edit Entry", content: <EditEntryFlyout closeFlyout={closeEdit} id={ row.id } inspection={inspection} /> } );
                    } } />
                </div>
            </div>
        </>
    );
}
