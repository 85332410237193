import { useMutation, useQuery } from '@apollo/client';
import {
  CreateSquawkDocument,
  CreateSquawkInput,
  Severity,
  Status,
  GetCraftDocument,
  GetSquawksDocument,
  GetComponentsDocument,
  GetCraftsDocument,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import JascSelect, { JascSystem } from 'components/JascCategories/JascSelect';
import DatePicker from 'components/DatePicker/DatePicker';
import Card from 'components/Card/Card';
import FilePicker from 'components/FilePicker/FilePicker';
import { FullFormikInput } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';

const StyledSelect = (props) => (
  <select
    className="rounded border px-2 w-2/3 block pl-3 pr-10 py-2 text-sm border-slate-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    {...props}></select>
);

const initialValues = {
  status: 'IN_PROGRESS',
  severity: 'WARNING',
  dateOpened: new Date(),
  title: '',
  description: '',
  component: '',
  systemsAffected: [],
};

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const New = function ({ closeFlyout }: { closeFlyout: () => void}) {
  const { craftId, user, organizationId } = useSession();
  const [createSquawk] = useMutation(CreateSquawkDocument, {
    refetchQueries: [
      { query: GetSquawksDocument, variables: { craftId } },
      GetCraftDocument,
      { query: GetCraftsDocument, variables: { organizationId } },
      { query: GetComponentsDocument, variables: { craftId } },
    ],
  });
  const { data: { craft } = { craft: undefined } } = useQuery(GetCraftDocument, { variables: { craftId } });
  const { data: { components } = { components: [] } } = useQuery(GetComponentsDocument, { variables: { craftId } });
  const [prefill, setPrefill] = useState(initialValues);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (components && components.length) {
      setPrefill((prev) => ({ ...prev, component: components[0].id }));
    }
  }, [components]);

  const handleSubmit = async (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
    const squawkInput: CreateSquawkInput = {
      status: values.status as Status,
      severity: values.severity as Severity,
      openedAt: values.dateOpened,
      title: values.title,
      description: values.description,
      craft: { connect: { id: craftId } },
      ...( values.component && {component: { connect: { id: values.component } }}),
      userProfile: { connect: { id: user?.userOrganizationProfileId } },
      systemsAffected: { connect: values.systemsAffected.map((system) => {
        return ({ id: system.split('/')[0] });
      }) },
    };
    await createSquawk({ variables: { input: squawkInput } });
    setSubmitting(false);
    setPrefill({ component: prefill.component, ...initialValues });
    closeFlyout();
  };

  return (
    <Formik enableReinitialize initialValues={prefill} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <Card className='m-4'>
            <dl>
              <div className="p-4 flex items-center justify-center w-full">
                <FullFormikInput name='title' label='Title' isVertical={true} className='w-full' />
              </div>
              <div className="p-4 flex items-center justify-between">
                <dt className="text-sm font-medium text-slate-500 w-1/3">Status</dt>
                <Field as={StyledSelect} name="status">
                  {Object.keys(Status).map((key) => (
                    <option key={key} value={Status[key]}>
                      {toTitleCase(Status[key].replace('_', ' '))}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="p-4 flex items-center justify-between">
                <dt className="text-sm font-medium text-slate-500 w-1/3">Severity</dt>
                <Field as={StyledSelect} name="severity">
                  {Object.keys(Severity).map((key) => (
                    <option key={key} value={Severity[key]}>
                      {toTitleCase(Severity[key].replace('_', ' '))}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="p-4 flex items-center justify-between">
                <dt className="text-sm font-medium text-slate-500 w-1/3">Component</dt>
                <Field as={StyledSelect} name="component">
                  {craft?.components.map((component) => (
                    <option key={component.id} value={component.id}>
                      {component.name}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="p-4 flex items-center justify-between">
                <div className='w-1/3 mb-[-15px]'>
                  <dt className="text-sm font-medium text-slate-500">Systems Affected</dt>
                  <a href='https://sdrs.faa.gov/documents/JASC_Code.pdf' target='_' className="text-xs font-medium text-slate-500 underline">(JASC Table And Definitions)</a>
                </div>
                <Field
                  as={JascSelect}
                  name="systemsAffected"
                  className="w-2/3"
                  onChange={(value) => {
                    setFieldValue('systemsAffected', value);
                  }}>
                  <option>1230 - Hydraulic Fuel servicing</option>
                </Field>
              </div>
              <div className="p-4 flex items-center justify-center">
                <dt className="text-sm font-medium text-slate-500 w-1/3">Date Opened</dt>
                <Field as={DatePicker} name="dateOpened"></Field>
              </div>
              <div className="p-4 flex flex-col">
                <dt className="text-sm font-medium text-slate-500 w-1/3">Attachments</dt>
                <FilePicker files={files} setFiles={setFiles} />
              </div>
              <div className="p-4">
                <dt className="text-sm font-medium text-slate-500 mb-2">Description</dt>
                <Field
                  as="textarea"
                  rows="4"
                  className="w-full border border-slate-300 rounded text-sm text-slate-500 p-2"
                  name="description"></Field>
              </div>
            </dl>
          </Card>
          <div className="px-4 mb-10 flex items-center justify-end">
            <Button
              type="submit"
              size='xs'
              disabled={isSubmitting}
              text='Create Squawk' />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default New;
